button {
  line-height: 90% !important;
}

p {
  line-height: 90% !important;
  opacity: 0.7;
  margin-top: 25px;
  font-size: small;
  font-weight: lighter;
  width:80%;
  display: inline-block;
  text-align: center;
}
