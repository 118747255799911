/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.system_button {
  cursor:pointer;
  opacity:0.8;
  position: absolute;
  right:10px;
  top:10px;
}

p {
  font-size: small;
}

